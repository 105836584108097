import React from 'react'
import { StyledLocation } from './StyledLocation'

export default function Lokacija() {
  return (
    <StyledLocation>
      <h1 id="Lokacija">Lokacija</h1>
      <iframe
        className="gooMap"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3591.7259745488286!2d19.23895131569481!3d42.450888337352914!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x134deb2b64833fe3%3A0xf67db57b36519c59!2s124%20Dalmatinska%20St%2C%20Podgorica%2C%20Montenegro!5e1!3m2!1sen!2s!4v1663511171243!5m2!1sen!2s"
        title="map"
        width={"800"}
        height={"450"}
        style={{ border: "0" }}
        loading="lazy"
      ></iframe>
      <h4>
        <img src="assets/maps-and-flags.png" alt="" className="iconss" />
        Dalmatinska 124, Podgorica, Crna Gora
      </h4>
    </StyledLocation>
  );
}
