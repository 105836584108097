import styled from "styled-components";

export const StyledFooter = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 30vh;
  background: rgb(59, 154, 225, 0.5);
  backdrop-filter: blur(20px);
  border-radius: 10px 10px 0 0;
  margin-top: 10px;
  padding: 1em;
  .footer-left {
    width: 13%;

    img {
      width: 100%;
    }
  }
  .footer-right {
    width: 13%;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-between;
    height: 100%;
    .rightf {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 120px;
      div {
        height: 30px;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        font-size: 1.1em;
        font-weight: 600;
        div {
          display: flex;
          align-items: center;
          flex-direction: row;
          justify-content: space-between;
          img {
            width: 20px;
          }
        }
      }
      button {
        margin: 35px 0 0 0;
      }
    }
    .send-message {
      outline: none;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: center !important;
      height: 0px;
      border: 1px solid;
      font-size: 1.1em;
      font-weight: 500;
      background-color: rgb(57, 110, 176, 0.5);
      color: white;
      width: 200px !important;
      height: 50px !important;
      border-radius: 30px;
      cursor: pointer;
      transition: all 0.2s ease-in-out;

      &:active,
      &:hover {
        transition: all 0.2s ease-in-out;
        background-color: rgb(57, 110, 176, 0.7);
      }
    }
  }
  @media (max-width: 1024px) {
    height: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .footer-left {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .footer-right {
      width: 95%;
      height: 20vh;
      .rightf {
        flex-direction: row;
        width: 100%;
        margin-bottom: 20px;
        div {
          width: 60%;
          flex-direction: column;
          div {
            justify-content: space-evenly;
            flex-direction: row;
          }
        }
        a {
          width: 35%;
        }
      }
    }
  }
  @media (max-width: 564px) {
    .footer-right {
      width: 95%;
      height: auto;
      .rightf {
        flex-direction: column;
        width: 100%;
        margin-bottom: 20px;
        margin-top: 20px;
        div {
          width: 80%;
          flex-direction: column;
          div {
            flex-direction: row;
          }
        }
        a {
          width: 100%;
        }
      }
    }
    .footer-left {
      width: 45%;
    }
  }
  .footer-left {
    margin-top: 20px;
  }
`;
