import styled from "styled-components";
export const StyledSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: rgb(59, 154, 225, 0.5);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 37%);
  backdrop-filter: blur(20px);
  border-radius: 10px;
  margin-top: 10px;
  h1 {
    color: #fff;
    &:target {
      scroll-margin-top: 75px;
    }
  }
  .about-us {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    h4 {
      margin-bottom: 20px;
      width: 70%;
      line-height: 1.4;
      font-size: 1.1rem;
    }
  }
  @media (max-width: 1024px) {
    .about-us {
      h4 {
        width: 90%;
        font-size: 1rem;
      }
    }
  }
`;
