import styled from "styled-components";

export const StyledSendMessage = styled.div`
  display: ${(props) => (props.popup ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  background: rgb(59, 154, 225, 0.4);
  backdrop-filter: blur(20px);

  z-index: 99;
  .form-send {
    position: absolute;
    top: 30vh;
    box-shadow: 0 8px 32px 0 rgb(31 38 135 / 37%);
    width: 20%;
    height: 40vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(57, 110, 176, 0.5);
    border-radius: 20px;
    .close {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 10px;
      right: 10px;
      width: 30px;
      height: 30px;
      border-radius: 50%;

      font-weight: bolder;
      cursor: pointer;
      color: rgb(15 55 108);
      font-size: 20px;
      background-color: rgb(57, 110, 176, 0.9);
    }
    .inputs {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      margin-top: 40px;
      height: 40%;
      width: 60%;
      .input {
        width: 100%;
        height: 30px;
        outline: none;
        border: none;
        border-bottom: 1px solid rgb(15 55 108);
        background: none;
        font-size: 1em;

        &::placeholder {
          color: rgb(15 55 108);
          font-weight: bolder;
        }
        
      }
      
      .input-area {
        background: none;
        width: 100%;
        height: 60px;
        border-radius: 10px;
        border: 1px solid rgb(15 55 108);
        position: relative;
        font-size: 1em;
        padding: 5px;
        resize: none;
        margin-top: 10px;
        &::placeholder {
          position: absolute;
          top: 5px;
          left: 5px;
          color: rgb(15 55 108);
          font-weight: bolder;
        }
        &:focus-visible {
          outline: none;
        }
      }
    }
    .sendm {
      outline: none;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: center !important;
      height: 0px;
      border: 1px solid;
      font-size: 1.1em;
      font-weight: 500;
      background-color: rgb(57, 110, 176, 0.5);
      color: white;
      width: 100px !important;
      height: 35px !important;
      border-radius: 30px;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      margin-top: 20px;

      &:active,
      &:hover {
        transition: all 0.2s ease-in-out;
        background-color: rgb(57, 110, 176, 0.7);
      }
    }
  }
  @media (max-width: 1024px) {
    .form-send {
      width: 80%;
    }
  }
`;
