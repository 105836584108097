import React from "react";
import { useState } from "react";
//
import { GiHamburgerMenu } from "react-icons/gi";
//
import { StyledNavbar } from "./StyledNavbar";

export default function Navbar() {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const list = ['Naslovna', 'O nama',  'Portfolio', 'Lokacija', 'Kontakt']
  const navbarEl = () => {
    const elements = [];
    list.map(el => {
      elements.push(
        <li key={el}>
          <a
            href={`#${el}`}
            onClick={() => {
              setIsNavExpanded(!isNavExpanded);
            }}
          >
            {el}
          </a>
        </li>
      );
    })
    return elements;
  }
  return (
    <StyledNavbar>
      <a href="#Naslovna" className="brand-name">
        <img src="assets/meplogo.png" alt="" />
      </a>

      <GiHamburgerMenu
        className="hamburger"
        onClick={() => {
          setIsNavExpanded(!isNavExpanded);
        }}
      />
      <div
        className={
          isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
        }
      >
        <ul>{navbarEl()}</ul>
      </div>
    </StyledNavbar>
  );
}


