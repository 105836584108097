import styled from "styled-components";

export const StyledPortfolio = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: rgb(59, 154, 225, 0.5);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 37%);
  backdrop-filter: blur(20px);
  border-radius: 10px;
  margin-top: 10px;
  h1 {
    padding: 12px 0;
    color: #fff;
    &:target {
      scroll-margin-top: 75px;
    }
  }
  h4 {
    font-size: 1.1rem;
  }
  .grid {
    background: rgb(57, 110, 176, 0.5);
    color: rgb(249, 245, 235);
    box-shadow: 0 8px 10px 0 rgba(31, 38, 135, 37%);
    backdrop-filter: blur(20px);
    border-radius: 10px;
    width: 95%;
    margin-top: 20px;
  }
  .grid1 {
    display: flex;
    justify-content: space-between;
    img {
      width: 40%;
      object-fit: cover;
      border-radius: 30px;
      padding: 1.2em;
    }
    h4 {
      padding-top: 20px;
      width: 15%;
      line-height: 1.4;
    }
  }
  .grid2 {
    display: flex;
    justify-content: space-evenly;
    img {
      width: 40%;
      object-fit: cover;
      border-radius: 30px;
      padding: 1.2em;
    }
    h4 {
      padding-left: 16px;
      padding-top: 20px;
      width: 35%;
      line-height: 1.4;
    }
  }

  .grid3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    h4 {
      width: 80%;
      padding: 20px 0 10px;
    }
    .swiper {
      width: 95%;
      height: 600px;
      margin: 20px 0;
      display: flex;
      align-items: center;
      .swiper-wrapper {
        display: flex;

        align-items: center;
        height: 480px;
        .swiper-slide {
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            height: 100%;
            border-radius: 30px;
          }
        }
      }
    }
    .img-div1 {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      padding: 1em;
      img:first-child {
        width: 54%;
      }
      img {
        width: 40%;
      }
    }
    .img-div2 {
      display: flex;
      padding: 1em;

      align-items: center;
      justify-content: space-evenly;

      img {
        width: 49%;
      }
    }
  }
  .last {
    padding: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 20px;
    h4 {
      width: 70%;
    }
  }

  @media (max-width: 1024px) {
    margin-bottom: 40px;
    .grid {
      align-items: center;
      flex-direction: column;
    }
    .grid1 {
      h4 {
        order: 1;
        width: 90%;
      }
      img {
        order: 2;
        width: 80%;
      }
    }
    .grid2 {
      h4 {
        width: 90%;
      }
      img {
        width: 80% !important;
      }
    }
    .grid3 {
      .swiper {
        height: 400px;
        .swiper-wrapper {
          height: unset;
          .swiper-slide {
            img {
              width: 60%;
            }
          }
        }
      }
      .img-div1 {
        padding: 10px;
        flex-direction: column;
        img {
          width: 80%;
          padding: 5px;
        }
      }
      .img-div2 {
        flex-direction: column;
        img {
          width: 80%;
          padding: 5px;
        }
      }
    }
  }
  @media (max-width: 576px) {
    .swiper {
      height: 290px !important;
      .pagination-bullet {
        background-color: red;
      }
      .swiper-wrapper {
        height: unset;

        .swiper-slide {
          img {
            width: 90% !important;
          }
        }
      }
    }
  }
`;
