import styled from "styled-components";

export const StyledHome = styled.div`
position: relative;
  margin-top: -70px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  h1 {
    margin-bottom: 20px;
  }
  .homepic {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    border-radius: 10px;
    filter: brightness(63%);
  }
  .title {
    width: 30%;
    position: absolute;
    top: 40%;
    left: 32.5%;
    z-index: 9;
    background: rgb(205, 240, 234, 0.35);
    box-shadow: 0 3px 7px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(20px);
    border-radius: 10px;
    text-align: center;
    color: #333;

    .title1 {
      font-size: 7em;
      font-weight: lighter;
      display: grid;
      grid-auto-flow: column;
      grid-column-gap: 90px;
    }
    .title2 {
      font-size: 3.5em;
      display: grid;
      grid-auto-flow: column;
      grid-column-gap: 40px;
    }
  }
  @media (max-width: 1024px) {
    height: 50vh;

    .title {
      width: auto;
      padding: 1em;
      top: unset;
      left: unset;
      box-shadow: 0 3px 7px 0 rgba(31, 38, 135, 0.57);
      .title1 {
        font-size: 2em;
      }
      .title2 {
        font-size: 1.75em;
      }
    }
  }
`;

export const StyledSlider = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 25px;
  opacity: 0.85;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  border-radius: 10px;

  .carousel {
    border-radius: 10px;
  }
  
`;
