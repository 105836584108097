import { useState } from "react";
import Footer from "./components/Footer/Footer";
import Homepage from "./components/Homepage/Homepage";
import Lokacija from "./components/Location/Location";
import MadeBy from "./components/MadeBy";
import Navbar from "./components/Navbar/Navbar";
import Portfolio from "./components/Portfolio/Portfolio";
import Section from "./components/Section/Section";
import SendMessage from "./components/SendMessage/SendMessage";


function App() {
  const [popup, setPopup] = useState(false);
  const ShowPopup = () => {
    setPopup(true);
    window.scrollTo(0, 0);
  };

  const HidePopup = () => {
    setPopup(false);
  };
  
  return (
    <div className="container">
      <Navbar />
      <Homepage />
      <Section />
      <Portfolio/>
      <Lokacija />
      <SendMessage hidePopup={HidePopup} popup={popup} />
      <Footer showPopup={ShowPopup} />
      <MadeBy />
    </div>
  );
}

export default App;
