import React from "react";
import { StyledSendMessage } from "./StyledSendMessage";

export default function SendMessage({ hidePopup, popup }) {
    const checkEmpty =()=>{
      if (
        document.getElementById("name").value === "" ||
        document.getElementById("email").value === "" ||
        document.getElementById("msg").value === ""
      ) {
        alert("Morate popuniti sva polja!");
      } else {
        document.getElementById("form").submit();
      }
    }
  return (
    <StyledSendMessage popup={popup}>
      <form
        action="https://getform.io/f/4977b672-f86b-4a01-b09f-03a6f7753d49"
        id="form"
        method="post"
        name="form"
        className="form-send"
      >
        <div className="close" onClick={() => hidePopup()}>
          X
        </div>
        <div className="inputs">
          <input
            className="input"
            id="name"
            name="name"
            placeholder="Ime"
            type="text"
          />
          <input
            className="input"
            id="email"
            name="email"
            placeholder="Email"
            type="text"
          />
          <textarea
            className="input-area"
            id="msg"
            name="message"
            placeholder="Poruka"
          ></textarea>
        </div>
        <div className='sendm' onClick={checkEmpty}>Posalji</div>
      </form>
    </StyledSendMessage>
  );
}
