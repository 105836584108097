import styled from "styled-components";

export const StyledNavbar = styled.nav`
  position: sticky;
  top: 0;
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 50px;
  color: black;
  background: rgb(59, 154, 225, 0.5);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 37%);
  backdrop-filter: blur(20px);
  z-index: 999;

  border: 1px solid rgba(255, 255, 255, 0.18);
  .brand-name {
    position: relative;
    width: 120px;
    align-items: center;
    justify-content: center;
    display: flex;
    text-decoration: none;
    color: black;
    font-size: 1.3rem;
    margin-left: 1rem;
    img {
      width: 75%;
    }
  }
  .navigation-menu {
    margin-left: auto;
    ul {
      display: flex;
      padding: 0;
      li {
        list-style-type: none;
        margin: 0 1rem;
        a {
          text-decoration: none;
          display: block;
          width: 100%;
          font-size: 25px;
          font-weight: 800;
          color: black;
          transition: 0.3s ease-in-out;
          position: relative;
          &:after {
            content: "";
            position: absolute;
            width: 100%;
            transform: scaleX(0);
            height: 2px;
            bottom: 0;
            left: 0;
            background-color: #e7410c;
            transform-origin: bottom right;
            transition: transform 0.25s ease-out;
          }
          &:hover {
            color: #e7410c;
            transition: 0.3s ease-in-out;
            &:after {
              transform: scaleX(1);
              transform-origin: bottom left;
            }
          }
        }
      }
    }
  }
  .hamburger {
    border: 0;
    height: 40px;
    width: 40px;
    padding: 0.5rem;
    border-radius: 50%;
    cursor: pointer;
    transition: 2s ease-in-out;
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    display: none;
    color: #e7410c;
  }
  @media (max-width: 1024px) {
    padding: 0px;
    .brand-name {
      width: 100px;
      align-items: center;
      justify-content: center;
      display: flex;
      img {
        width: 90%;
      }
    }
    .hamburger {
      display: block;
    }
    .navigation-menu {
      ul {
        z-index: 998;
        transform: translateY(-500px);
        position: absolute;
        top: 75px;
        left: 0;
        flex-direction: column;
        width: 100%;
        background-color: white;
        transition: all 0.5s ease-in-out;

        li {
          text-align: center;
          margin: 0;
          position: relative;
          box-shadow: 0px 25px 8px -29px rgb(88, 88, 88);
          &:before {
            content: "";
            position: absolute;
            left: 100px;
            bottom: 0;
            height: 1px;
            width: 50%; /* or 100px */
          }
          a {
            width: 100%;
            padding: 1.5rem 0;
            font-size: unset;
            font-weight: unset;
            color: #333;
            font-weight: 600;
          }
        }
      }
    }
    .navigation-menu.expanded {
      ul {
        transform: translateY(0);
        height: auto;
        transition: all 0.3s ease-in-out;
        background-color: rgb(140 184 217);
        box-shadow: 0 8px 32px 0 rgb(31 38 135 / 37%);
        border-radius: 10px;
      }
    }
  }
`;
