import React from "react";
//
import { StyledHome } from "./StyledHomepage";
//
export default function Homepage() {
  return (
    <StyledHome id="Naslovna">
      <img src="assets/homepic.png" alt="Home" className="homepic" />
      <div className="title">
        {" "}
        <div className="title1">
          <span>M</span>
          <span>E</span>
          <span>P</span>
        </div>
        <div className="title2">
          <span>P</span>
          <span>L</span>
          <span>A</span>
          <span>N</span>
        </div>
      </div>
    </StyledHome>
  );
}


