import React from "react";
import { StyledSection } from "./StyledSection";


export default function Section() {
  return (
    <StyledSection>
      <div className="about-us">
        <h1 id="O nama">O nama</h1>
        <h4>
          MEP PLAN je osnovan je 2021. godine s ciljem da svojim klijentima
          ponudi efikasnu i kompletnu uslugu, te cjelokupno vođenje MEP projekta
          i stručnu podršku tokom čitavog trajanja procesa projektovanja, kao i
          izvođenja. Osnovna djelatnost našeg biroa je projektovanje MEP
          instalacija za objekate visokogradnje i niskogradnje. MEP instalacije
          obuhvataju instalacije mašinske, elektro kao i hidro faze. U
          nedostatku i shvatanju današnjeg tržišta visokogradnje, gdje se u fazi
          projektovanja najveća pažnja posvećuje arhitektonskom i građevinskom
          dijelu projektne dokumentacije, zanemaruje se činjenica, da samim
          razvojem ove dvije grane, povećava se značaj MEP instalacija, koje su
          u današnjim vremenima energetske efikasnosti, od velikog značaja kako
          za samo unapređenje tehnologije objekta, kao i njegovu energetsku
          iskorišćenost. U ovome naš tim vidi priliku da se projektovanju
          prilazi iz više uglova, gdje će se problemi poput neusklađenosti
          projekata arhitekture i građevine sa MEP instalacijama, dovede na
          veoma prikladan nivo, pa čak i da ga eliminiše kao standardnu pojavu u
          fazi izvođenja.
        </h4>
        <h4>
          Svakom projektu pristupamo sa punim kreativnim angažmanom našeg
          iskusnog tima, pri čemu nam je primarni cilj postići uzajamno
          povjerenje sa klijentima, poštujući i primijenijući zakone i preporuke
          iz najsavremenijh standarda u građevinarstvu, a da su konačnim
          projektom zadovoljne obje strane.
        </h4>
        <h4>
          Najčešće su to objekti stambene namjene, ali i poslovne, turističke,
          kao i objekti javne infrastrukture. Vođeni zahtjevima naših cijenjenih
          klijenata, te na bazi iskustva i znanja obezbjeđujemo vođenje MEP
          projekata, krenuvši od analiza i idejnih rješenja, uključujući
          kompletnu izradu projektne dokumentacije. Moramo naglasiti veliki broj
          naših izvedenih projekata, kao i one koji se u ovom trenutku
          realizuju. Vjerujemo da nas naši projekti, naš tim i naš kontinuitet
          rada izdvaja od drugih, te da će to svaki klijent lako prepoznati.
        </h4>
      </div>
    </StyledSection>
  );
}
