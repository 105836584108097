import React from "react";
import { StyledPortfolio } from "./StyledPortfolio";
import { data } from "../../data";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
export default function Portfolio() {
  const renderSlides = (el) => {
    const slides = [];
    if (el.type === 3) {
      
      el.img.map((e) => {
        switch (el.id) {
          case 4:
            slides.push(
              <SwiperSlide key={el.id}>
                <img src={e} alt="" />
              </SwiperSlide>
            );

            break;
          case 6:
            slides.push(
              <SwiperSlide key={el.id}>
                <img src={e} alt="" />
              </SwiperSlide>
            );

            break;
          default:
            break;
        }
      });
    }
    return slides;
  };

  const renderContent = (data) => {
    const divs = [];

    data.map((el) => {
      switch (el.type) {
        case 1:
          divs.push(
            <div className="grid1 grid" key={el.id}>
              <img src={el.img[0]} alt="" />
              <h4>{el.text}</h4>
              <img src={el.img[1]} alt="" />
            </div>
          );
          break;
        case 2:
          divs.push(
            <div className="grid2 grid" key={el.id}>
              <h4>{el.text}</h4>
              <img src={el.img[0]} alt="" />
            </div>
          );
          break;
        case 3:
          divs.push(
            <div className="grid3 grid" key={el.id}>
              <h4>{el.text}</h4>
              <Swiper
                modules={[Pagination, Autoplay, Navigation]}
                pagination={{ clickable: true, dynamicBullets: true }}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                loop={true}
              >
                {renderSlides(el)}
              </Swiper>
            </div>
          );
          break;
        case 4:
          divs.push(
            <div className="last grid" key={el.id}>
              <h4>{el.text}</h4>
            </div>
          );
          break;

        default:
          break;
      }
    });
    return divs;
  };
  return (
    <StyledPortfolio>
      <h1 id="Portfolio">Portfolio</h1>
      {renderContent(data)}
    </StyledPortfolio>
  );
}
