import React from 'react'
import styled from 'styled-components';
function MadeBy() {
  return (
    <StyledMadeBy>
      © 2022 Design by{" "}
      <a
        href="https://www.linkedin.com/in/omar-be%C5%A1li%C4%87-2098471a1/"
        target="_blank"
        rel="noreferrer"
      >
        &nbsp; Omar.
      </a>
    </StyledMadeBy>
  );
}

export default MadeBy;

const StyledMadeBy = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(59, 154, 225, 0.5);
  backdrop-filter: blur(20px);
  padding-bottom: 10px;
  a{
    text-decoration: none;
    font-weight: 900;
  }
`;